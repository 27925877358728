import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import EChartsReact from 'echarts-for-react';
import store from 'client/store';
import t from 'i18n';
import { getLineOption } from './options';
import { Text } from '@consta/uikit/Text';
//! без этих импортов не будет работать русская локализация
import * as echarts from 'echarts';
import ruLocale from 'echarts/i18n/langRU';

@inject('graphicReportStore')
@observer
export class Chart extends Component {
	store = null;

	constructor(props) {
		super(props);
		this.store = props.graphicReportStore;
	}

	getOptions = () => {
		return this.props.option;
	};

	render() {
		const { isLoading, getParameterName } = this.store;
		const { value, paramId, option } = this.props;

		return (
			<div className='chart'>
				<Text view='primary'>
					{getParameterName(value.param)}, [{value.min} : {value.max}]
				</Text>
				<EChartsReact
					loadingOption={{
						text: t('loading'),
						textColor: '#000',
						maskColor: 'rgba(255, 255, 255, 0.8)',
						fontSize: 14,
						showSpinner: true,
						spinnerRadius: 10,
						lineWidth: 4,
						fontWeight: 'normal',
						fontStyle: 'normal',
						fontFamily: 'sans-serif',
					}}
					option={isLoading ? {} : option}
					echarts={echarts}
					opts={{ locale: store.local.appLanguage }}
				/>
			</div>
		);
	}
}
